<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="确认接单"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <span style="margin-left:50px;">确认接单需要设置遗体接运人、接运车辆。</span>
        <a-form-item label="接运人">
          <a-select
            v-decorator="['user_id', {
              initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,
              rules: [
                { required: true, message: '请选择接运人' },
              ]
            }]"
            placeholder="请选择接运人"
            @load="loadingUserOptions"
            @change="handlePhoneNumberChange"
          >
            <a-select-option
              v-for="user in userOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.emp_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="联系电话">
          <a-input
            :disabled="true"
            v-decorator="['phone_number', {
              initialValue: defaultUser.phone_number,
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="接运车辆">
          <a-select
            v-decorator="['car_id', {
              rules: [
                { required: true, message: '请选择接运车辆' },
              ]
            }]"
            placeholder="请选择车牌号"
            @focus="fetchCarOptions"
            @load="loadingCarOptions"
          >
            <a-select-option
              v-for="car in carOptions"
              :key="car.id"
              :value="car.id"
            >
              {{ car.car_no }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { message: '请输入备注' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findCarOptions } from '@/api/car'
import { confirmOrder } from '@/api/order'
import { findTenantTaskUserOptions, findUser } from '@/api/user'

export default {
  name: 'ConfirmOrder',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'confirm_order' }),
      submitting: false,
      defaultFileList: [],
      userOptions: [],
      defaultUser: {},
      carOptions: [],
      loadingCarOptions: false,
      loadingUserOptions: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
  },
  methods: {
    handlePhoneNumberChange(id) {
      this.form.setFieldsValue({ phone_number: undefined })
      if (!id) {
        return
      }
      findUser(id).then((res) => {
        if (res.code === 0) {
          this.form.setFieldsValue({ phone_number: res.data.phone_number })
        }
      })
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantTaskUserOptions({ task_type: 'body_feeder', order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data.option_list
          this.defaultUser = res.data.default_user
        }
        this.loadingUserOptions = false
      })
    },
    fetchCarOptions() {
      this.loadingCarOptions = true
      findCarOptions({ order_type: this.orderType, order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.carOptions = res.data.option_list
        }
        this.loadingCarOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          confirmOrder(Object.assign({ order_id: this.id }, values)).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
