var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"确认接单"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('span',{staticStyle:{"margin-left":"50px"}},[_vm._v("确认接单需要设置遗体接运人、接运车辆。")]),_c('a-form-item',{attrs:{"label":"接运人"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['user_id', {
            initialValue: _vm.defaultUser.id > 0 ? _vm.defaultUser.id : undefined,
            rules: [
              { required: true, message: '请选择接运人' } ]
          }]),expression:"['user_id', {\n            initialValue: defaultUser.id > 0 ? defaultUser.id : undefined,\n            rules: [\n              { required: true, message: '请选择接运人' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择接运人"},on:{"load":_vm.loadingUserOptions,"change":_vm.handlePhoneNumberChange}},_vm._l((_vm.userOptions),function(user){return _c('a-select-option',{key:user.id,attrs:{"value":user.id}},[_vm._v(" "+_vm._s(user.emp_name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            initialValue: _vm.defaultUser.phone_number,
            normalize: this.$lodash.trim
          }]),expression:"['phone_number', {\n            initialValue: defaultUser.phone_number,\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"接运车辆"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['car_id', {
            rules: [
              { required: true, message: '请选择接运车辆' } ]
          }]),expression:"['car_id', {\n            rules: [\n              { required: true, message: '请选择接运车辆' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择车牌号"},on:{"focus":_vm.fetchCarOptions,"load":_vm.loadingCarOptions}},_vm._l((_vm.carOptions),function(car){return _c('a-select-option',{key:car.id,attrs:{"value":car.id}},[_vm._v(" "+_vm._s(car.car_no)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            rules: [
              { message: '请输入备注' },
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['remark', {\n            rules: [\n              { message: '请输入备注' },\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }